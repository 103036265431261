import React from "react"
import { StoryGroup } from "components"

const LinkMap = {}

const stories = [
  {
    title: "Avatar Samples",
    postId: "88c596b671b7",
  },
  {
    title: "Spinner Samples",
    postId: "9c74a44dfbdf",
  },
  {
    title: "Button Samples",
    postId: "ffca0df8ffdb",
  },
  {
    title: "Button Samples 2",
    postId: "d8e0712c4650",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Block Samples",
  path: "react-block-samples",
}

const ReactBlockSamples = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactBlockSamples
